import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import MainContainer from "../domains/main/container";
import AddressContainer from "../domains/address/container";
import BlocksContainer from "../domains/blocks/container";
import TxContainer from "../domains/tx/container";
import TxsContainer from "../domains/txs/container";
import HeightContainer from "../domains/height/container";

import ErrorPage from "../domains/error";


function AppRouter() {
    return (
        <Switch>
            <Route exact path={'/'} component={MainContainer}/>
            <Route path={'/address/:address'} component={AddressContainer}/>
            <Route path={'/blocks/:page?'} component={BlocksContainer}/>
            <Route path={'/tx/:tx'} component={TxContainer}/>
            <Route path={'/txs/:height'} component={TxsContainer}/>
            <Route path={'/height/:height'} component={HeightContainer}/>
            <Route path={'/error'} component={ErrorPage}/>
            <Redirect to={'/error'}/>
        </Switch>
    )
}

export default AppRouter;
