import React from "react";
import banner_light from "../../@assets/images/02_banner.jpg";
import banner_dark from "../../@assets/images/03_banner.jpg";
import {THEME} from "../../constants";

function Banner({config}) {

    const {theme} = config;

    return (
        <div className="container-banner">
            <div className="banner">
                <a href="https://spacebot.ltd/" target="_blank">
                    <img className="img-banner" src={theme === THEME.light ? banner_light : banner_dark} alt="spacebot.ltd"/>
                </a>
            </div>
        </div>
    )
}

export default Banner;