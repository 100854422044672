export const us = {
    search: {
        title: 'Prizm Blockchain',
        placeholder: 'Search by Address / Tech / Block / Block height',
        button: 'Search',
        notSearch: 'The request was not found'
    },

    home: {
        nav_link_home: 'Main',
        block_cont_transaction: 'Transactions per hour',
        block_cont_transaction_hour: 'Blocks per hour',
        block_time_created_transaction: 'Block time',
        block_count_average_transaction: 'Average transaction number in a block',

        latest: {
            title: 'Latest blocks',
            block: 'Block',
            button: 'View all blocks',
        },

        transactions: {
            title: 'Recent transactions',
            button: 'View all transactions',
        }
    },

    selectRecord: {
        show: 'Show',
        toPage: 'On the page',
    },

    pagination: {
        page: 'Page',
        of: 'of',
    },

    table: {
        height: 'Block height',
        time: 'Time',
        transaction: 'Transactions',
        sum: 'Amount',
        fee: 'Fee',
        purse: 'Wallet',
        from: 'From',
        to: 'To',
        baseGoal: 'Basic goal',
        your: 'Your wallet',
        yours: 'To your wallet',
    },

    cardTx: {
        heightBlock: 'Block height',
        transaction: 'Transaction',
        block: 'Block',
        createdAt: 'Created at',
        sum: 'Sum',
        fee: 'Fee',
        recipient: 'Recipient',
        sender: 'Sender',
        confirmations: 'Confirmations in the network',
        senderPublicKey: 'Public key of the sender',
        signature: 'Signature',
        signatureHash: 'Signature hash',
        fullHash: 'Full hash',
    },

    cardBlock: {
        heightBlock: 'Block height',
        block: 'Block',
        createdAt: 'Created at',
        total: 'Total amount',
        count: 'Transaction number',
        numberOfTransactions: '%{count} transactions in a block',
        purse: 'Wallet',
        previousBlockHash: 'Previous block hash',
        generationSignature: 'Signature',
        generatorPublicKey: 'Public key',
        blockSignature: 'Signature of the block',
    },

    address: {
        purse: 'Wallet',
        publicKey: 'Public key',
        balanceNQT: 'Wallet balance',
        forged: 'Forged by the wallet node',
        transaction: 'Transactions',
        block: 'Blocks',
        notResult: 'No data',

        paramining: {
            dayIncome: 'Daily income',
            monthIncome: 'Monthly income',
            yearIncome: 'Income per year',
            paramining: 'Paramining',
            paraminingday: 'Daily paramining',
            structure: 'Structure',
            percent: 'Percentage',
        }
    },

    copy: {
        heightBlock: 'Block height copied',
        block: 'Block copied',
        address: 'Address copied',
        transaction: 'Transaction copied',
        senderPublicKey: 'Key copied',
    },

    clickPlaceholder: {
        transaction: 'Click ti view transactions',
        heightBlock: 'Click to view block height',
        address: 'Click to view account',
        nextBlock: 'See the next block',
        prevBlock: 'See the previous block',
    },

    errorNextHeight: 'Error of block creation',

    footer: {
        col_title_products: 'Products',
        col_title_news: 'News',
    }
};
