import React, {useState} from "react";
import {connect} from "react-redux";
import Search from "./index";
import {getAccount, getBlock, getBlockHeight, getTransaction} from "../../@api";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {I18n} from "react-redux-i18n";

function SearchContainer(props) {

    const [stateSearch, setStateSearch] = useState('');
    const history = useHistory();

    function handleSubmit(e) {
        e.preventDefault();

        if (!stateSearch.length) return false;

        Promise.all([
            getTransaction(stateSearch),
            getBlock(stateSearch, {includeTransactions: false}),
            getAccount(stateSearch),
            getBlockHeight(stateSearch),
        ]).then((response) => {
            const txt = response[0].data;
            const block = response[1].data || response[3].data;
            const account = response[2].data;

            if (txt['ecBlockHeight'] != null) {
                const {transaction} = txt;
                return redirectUrl('/tx/' + transaction);
            } else if (block['height'] != null) {
                const {height} = block;
                return redirectUrl('/height/' + height);
            } else if (account['errorDescription'] == null) {
                const {accountRS} = account;
                return redirectUrl('/address/' + accountRS);
            }

            return toast.error(I18n.t('search.notSearch'));
        })
    }

    function redirectUrl(url) {
        setStateSearch('');
        return history.push(url);
    }

    return <Search handleSubmit={handleSubmit}
                   stateSearch={stateSearch}
                   setStateSearch={setStateSearch}/>
}

const mapStateToProps = state => ({
    ...state.i18n,
    numberOfBlocks: state.app.getState.numberOfBlocks
});

const mapDispatchTopProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchTopProps)(SearchContainer)
