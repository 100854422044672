import React from "react";
import TransactionsTable from "../@common/table/transactions-table";
import WrapperTransactionBlockHOC from "../../@hooks";
import * as PropTypes from "prop-types";
import UIPreloader from "../@common/preloader";

function Txs(props) {

    const {txs: {transactions, pending}, getTxs} = props;

    return (
        <>
            <UIPreloader enable={pending}/>
            <TransactionsTable transactions={transactions} getNextTransactions={getTxs}/>
        </>
    )
}

Txs.propTypes = {
    txs: PropTypes.shape({
        transactions: PropTypes.array,
        pending: PropTypes.bool,
    }),
    getTxs: PropTypes.func,
};

export default (React.memo(WrapperTransactionBlockHOC(Txs)));
