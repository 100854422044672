import React from "react";
import InfoAddress from "./components/info-address";
import UIPreloader from "../@common/preloader";
import Paramining from "./components/paramining";
import Transactions from "./components/block-transactions/index";

function Address(props) {

    const {
        account,
        paramining,
        pending,
    } = props;

    return (
        <div className="block">
            <UIPreloader enable={pending}/>
            <InfoAddress account={account}/>
            <Paramining paramining={paramining}/>
            <Transactions {...props}/>
        </div>
    )
}

export default React.memo(Address);
