import React, {useEffect, useRef} from 'react';
import classnames from "classnames"

function UIPreloader(props) {

    const ref = useRef(null);
    const enable = props.enable;
    const classes = classnames(
        'preloader-data',
        {'enable': enable}
    );

    useEffect(() => {
        const el = ref.current.nextSibling;
        getSiblings(el);
    }, [enable]);

    function getSiblings(element) {
        if (element != null) {
            if (enable === true) {
                element.classList.add('disabled-div');
            } else {
                element.classList.remove('disabled-div');
            }
            const nextElement = element.nextSibling;
            if (typeof element === "object") getSiblings(nextElement);
        }
    }

    return (
        <div className={classes} ref={ref}>
            <div className="preloader-data-enable">
                <div className="sc-AykKG VdKZR"></div>
            </div>
        </div>
    );
}

export default UIPreloader;
