import {ACCOUNT_GENESIS, THEME, TYPE_APP_PENDING_STATE, TYPE_APP_STATE, TYPE_APP_THEME_STATE} from "../../constants";
import {getAccount, getConstants, getState} from "../../@api";

export function fetchInitDataApp() {
    return async (dispatch) => {
        Promise.all([
            getState(),
            getConstants(),
            getAccount(ACCOUNT_GENESIS),
        ]).then(response => {
            const getState = response[0].data;
            const getConstants = response[1].data;
            const genesis = response[2].data;

            dispatch(setStateApp({getState, getConstants, genesis}));
        })
            .catch(error => Promise.reject(error))
            .finally(() => dispatch(pending(false)))
    }
}

export function changeTheme() {
    return (dispatch, getState) => {
        const {app} = getState();
        const {theme} = app.config;
        const newTheme = (theme == THEME.light) ? THEME.dark : THEME.light;
        localStorage.setItem('theme', newTheme);

        dispatch(setStateAppTheme(newTheme))
    }
}

export const setStateAppTheme = theme => ({
    type: TYPE_APP_THEME_STATE,
    theme,
});

const setStateApp = data => ({
    type: TYPE_APP_STATE,
    payload: data,
    pending: false,
});

export const pending = (pending = true) => ({
    type: TYPE_APP_PENDING_STATE,
    pending: pending,
});
