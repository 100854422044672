import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import * as PropTypes from "prop-types";

function UICopy(props) {

    const {text, alert} = props;

    return (
        <CopyToClipboard
            text={text}
            onCopy={() => toast.info(alert)}>
            <button className="btn btn-xs btn-soft-primary page-link"
                    style={{cursor: 'pointer', display: 'inline', marginLeft: '5px'}}>
                <i className="fa fa-clone" aria-hidden="true"></i>
            </button>
        </CopyToClipboard>
    );
}

UICopy.propTypes = {
    text: PropTypes.any,
    alert: PropTypes.string.isRequired,
};

export default UICopy;
