import React, {useEffect} from "react";
import {connect} from "react-redux";
import Blocks from "./index"
import {fetchBlocks} from "../../store/blocks/action";
import {Element, scroller, animateScroll} from 'react-scroll';
import {useHistory} from "react-router-dom";

function BlocksContainer(props) {

    const history = useHistory();
    const {page} = props.match.params;

    function onNextPageHandel() {
        let nextPage = page;
        if (nextPage == null) {
            nextPage = 2;
        } else {
            nextPage = parseInt(page) + 1;
        }
        history.push(`/blocks/${nextPage}`);
        fetch(nextPage);
    }

    function fetch(page) {
        props.getBlocks(page).then(() => animateScroll.scrollToTop());
    }

    useEffect(() => {
        fetch(page);
    }, []);

    return <Blocks blocks={props.blocks} getBlocks={onNextPageHandel}/>;
}

const mapStateToProps = state => ({
    blocks: state.blocks,
});

const mapDispatchTopProps = dispatch => ({
    getBlocks: (page) => dispatch(fetchBlocks(page)),
});

export default connect(mapStateToProps, mapDispatchTopProps)(React.memo(BlocksContainer));
