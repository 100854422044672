import React from "react";
import {Link} from "react-router-dom";
import prizmIcon from "../@assets/images/prizm-icon.png";
import {I18n} from "react-redux-i18n";

function Header() {
    return (
        <header id="header" className="u-header">
            <div className="u-header__section py-1">
                <div id="logoAndNav" className="container">
                    <nav
                        className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
                        <div className="w-lg-auto">
                            <Link className="navbar-brand  pt-md-0" to={`/`}>
                                <img id="logo-header" width="160" src={prizmIcon} alt="Etherscan Logo"
                                     style={{paddingTop: '6px'}}/>
                            </Link>
                        </div>
                        <div>
                            <button type="button" className="navbar-toggler btn u-hamburger"
                                    aria-label="Toggle navigation"
                                    aria-expanded="false"
                                    aria-controls="navBar"
                                    data-toggle="collapse"
                                    data-target="#navBar">
                                    <span id="hamburgerTrigger" className="u-hamburger__box">
                                        <span className="u-hamburger__inner"/>
                                    </span>
                            </button>
                        </div>
                        <div className="d-flex flex-column w-100">
                            <div id="navBar" className="navbar-collapse u-header__navbar-collapse order-md-2 collapse">
                                <ul className="navbar-nav u-header__navbar-nav px-2 px-md-0">
                                    <li className="nav-item u-header__nav-item">
                                        <Link className="nav-link u-header__nav-link"
                                              to={`/`}>{I18n.t('home.nav_link_home')}</Link>
                                    </li>
                                    <li className="nav-item u-header__nav-item">
                                        <a className="nav-link u-header__nav-link" href="https://bit.team/"
                                           target="_blank" rel="noopener noreferrer">BIT.TEAM</a>
                                    </li>
                                    <li className="nav-item u-header__nav-item">
                                        <a className="nav-link u-header__nav-link" href="https://spacebot.ltd"
                                           target="_blank" rel="noopener noreferrer">SPACEBOT</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header;
