import {TYPE_BLOCKS_PENDING_STATE, TYPE_BLOCKS_STATE} from "../../constants";

const blockDefaultState = {
    blocks: [],
    pending: true,
};

export const blocksReducer = (state = blockDefaultState, action) => {
    switch (action.type) {
        case TYPE_BLOCKS_STATE :
            return {
                ...state,
                blocks: action.payload,
                pending: false,
            };

        case TYPE_BLOCKS_PENDING_STATE :
            return {
                ...state,
                pending: action.pending,
            };
        default :
            return state;
    }
};
