import client from "./client";
import {encodeQueryData} from "../@helper";

export const getState = () =>
    client.get('getState');

export const getConstants = () =>
    client.get('getConstants');

export const getBlocks = (params = {}) =>
    client.get(encodeQueryData(`getBlocks`, params));

export const getBlock = (block, params = {}) =>
    client.get(encodeQueryData(`getBlock&block=${block}`, params));

export const getBlockHeight = (height, params = {}) =>
    client.get(encodeQueryData(`getBlock&height=${height}`, params));

export const getBlocksTransactions = (params = {}) =>
    client.get(encodeQueryData(`getBlocks&includeTransactions=true`, params));

export const getTransaction = (id, params = {}) =>
    client.get(encodeQueryData(`getTransaction&transaction=${id}`, params));

export const getAccount = (account, params = {}) =>
    client.get(encodeQueryData(`getAccount&account=${account}`, params));

export const getAccountSearch = (query) =>
    client.get(encodeQueryData(`searchAccounts&query=${query}`));

export const getAccountTransactions = (account, params = {}) =>
    client.get(encodeQueryData(`getBlockchainTransactions&account=${account}`, params));

export const getAccountBlocks = (account, params = {}) =>
    client.get(encodeQueryData(`getAccountBlocks&account=${account}&includeTransactions=false`, params));

export const getPara = (account, params = {}) =>
    client.get(encodeQueryData(`getPara&account=${account}`), params);

