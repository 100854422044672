import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {pending} from "../../store/app/action";
import "./style.css";

function ErrorPage(props) {

    const {pending} = useSelector(state => state.app);

    useEffect(() => {
        props.offPending();
    }, [pending]);

    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>Oops!</h1>
                    <h2>Error</h2>
                </div>
                <Link to={`/`}>Go TO Homepage</Link>
            </div>
        </div>
    );
}

const mapDispatchTopProps = dispatch => ({
    offPending: () => dispatch(pending(false))
});

export default connect(null, mapDispatchTopProps)(ErrorPage);
