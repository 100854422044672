import {getAccount, getAccountBlocks, getAccountTransactions, getPara} from "../../@api";
import {TYPE_ACCOUNT_PENDING_STATE, TYPE_ACCOUNT_RESET_STATE, TYPE_ACCOUNT_STATE} from "../../constants";
import {setParamining} from "../../@helper";
import {addressDefaultState} from "./reducer";
import {toast} from "react-toastify";
import {I18n} from "react-redux-i18n";

export function fetchDataAccount(address) {
    return async (dispatch, getState) => {
        const {account: {accountRS}} = getState().address;
        if (accountRS !== address) {
            dispatch(reset());
            dispatch(pending());
        }

        return await Promise.all([
            getAccount(address),
            getPara(address),
            getAccountTransactions(address),
            getAccountBlocks(address),
        ]).then(response => {
            const {transactions, blocks} = getState().address;
            const accountDate = response[0].data;
            const para = response[1].data;
            const txs = response[2].data.transactions;
            const bks = response[3].data.blocks;

            if (accountDate['errorDescription'] != null) {
                toast.info(I18n.t('address.notResult'));
                return false;
            }

            dispatch(setAccountState({
                account: Object.assign({...addressDefaultState.account}, accountDate),
                paramining: setParamining(para),
                transactions: txs.concat(transactions.slice(txs.length, transactions.length)),
                blocks: bks.concat(blocks.slice(bks.length, blocks.length)),
                moreT: txs.length === 100,
                moreB: bks.length === 100
            }));
            return Promise.resolve(accountDate);

        }).finally(() => dispatch(pending(false)));
    }
}

export function fetchNextTransactionsAccount() {
    return (dispatch, getState) => {
        const {transactions, account} = getState().address;
        const oldTransactions = transactions;
        const count = oldTransactions.length;
        dispatch(pending());
        getAccountTransactions(account.accountRS, {firstIndex: count, lastIndex: count + 100})
            .then(response => {
                const nextTransactions = response.data.transactions;
                const newTransactions = oldTransactions.concat(nextTransactions);

                dispatch(setAccountState({
                    transactions: newTransactions,
                }))
            }).finally(() => dispatch(pending(false)))
    }
}

export function fetchNextBlocksAccount() {
    return (dispatch, getState) => {
        const {blocks, account} = getState().address;
        const oldBlocks = blocks;
        const count = oldBlocks.length;
        dispatch(pending());
        getAccountBlocks(account.accountRS, {firstIndex: count, lastIndex: count + 100})
            .then(response => {
                const nextBlocks = response.data.blocks;
                const newBlocks = oldBlocks.concat(nextBlocks);

                dispatch(setAccountState({
                    blocks: newBlocks,
                }))
            }).finally(() => dispatch(pending(false)))
    }
}

const setAccountState = data => ({
    type: TYPE_ACCOUNT_STATE,
    payload: data,
});

const pending = (pending = true) => ({
    type: TYPE_ACCOUNT_PENDING_STATE,
    pending: pending,
});

const reset = () => ({
    type: TYPE_ACCOUNT_RESET_STATE,
    payload: addressDefaultState,
});
