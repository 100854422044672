import React, {useEffect} from "react";
import {connect} from "react-redux";
import Address from "./index";
import {fetchDataAccount, fetchNextBlocksAccount, fetchNextTransactionsAccount,} from "../../store/address/action";
import {animateScroll} from 'react-scroll';

function AddressContainer(props) {

    const {address} = props.match.params;

    useEffect(() => {
        props.getDataAccount(address).then(() => animateScroll.scrollToTop());
        const id = setInterval(() => props.getDataAccount(address), 60000);
        return () => clearInterval(id);
    }, [address]);

    return (
            <Address {...props.address}
                     {...props.i18n}
                     getNextTransactions={props.getNextTransactions}
                     getNextBlocks={props.getNextBlocks}/>
    )
}

const mapStateToProps = state => ({
    address: state.address,
    i18n: state.i18n
});

const mapDispatchTopProps = dispatch => ({
    getDataAccount: (address) => dispatch(fetchDataAccount(address)),
    getNextTransactions: () => dispatch(fetchNextTransactionsAccount()),
    getNextBlocks: () => dispatch(fetchNextBlocksAccount())
});

export default connect(mapStateToProps, mapDispatchTopProps)(React.memo(AddressContainer));
