import React from "react";
import * as PropTypes from "prop-types";

function UIPagination({onChangeNextPage}) {

    return (
        <div className="d-md-flex justify-content-center my-3">
            <button
                className="page-link"
                data-name="Next"
                onClick={onChangeNextPage}>
                <span aria-hidden="True">Загрузить еще</span>
            </button>
        </div>
    );
}

UIPagination.propTypes = {
    onChangeNextPage: PropTypes.func.isRequired,
};

export default UIPagination;
