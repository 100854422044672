import React from "react";
import UIPreloader from "../@common/preloader";
import {I18n} from "react-redux-i18n";
import {Link} from "react-router-dom";
import UICopy from "../@common/copy";
import {asBaseTarget, asDateString, asFormatNumber, asPayload} from "../../@helper";

function Height(props) {

    const {
        block: {
            height,
            block,
            timestamp,
            totalAmountNQT,
            numberOfTransactions,
            generatorRS,
            previousBlockHash,
            generationSignature,
            generatorPublicKey,
            payloadHash,
            blockSignature,
            payloadLength,
            baseTarget
        },
        pending,
    } = props;

    return (
        <div className="card">
            <div className="tab-content">
                <div className="tab-pane fade active show" id="overview" role="tabpanel" aria-labelledby="home-tab">
                    <UIPreloader enable={pending}/>
                    <div id="ContentPlaceHolder1_maintable" className="card-body">
                        <div className="row align-items-center  mt-1">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                {I18n.t('cardBlock.heightBlock')}:
                            </div>
                            <div className="col-md-9">
                                <div className="d-flex">
                                    <span className="font-weight-sm-bold mr-2">{height}</span>
                                    <Link className="btn btn-xs btn-icon btn-soft-info mr-1"
                                          to={`/height/${+height - 1}`}
                                          title={I18n.t('clickPlaceholder.prevBlock')}>
                                        <i className="fa fa-chevron-left btn-icon__inner"/>
                                    </Link>
                                    <Link className="btn btn-xs btn-icon btn-soft-info mr-1"
                                          to={`/height/${+height + 1}`}
                                          title={I18n.t('clickPlaceholder.nextBlock')}>
                                        <i className="fa fa-chevron-right btn-icon__inner"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardBlock.block')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {block}
                                    <UICopy text={block} alert={I18n.t('copy.block')}/>
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space mt-2"/>
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                {I18n.t('cardBlock.createdAt')}:
                            </div>
                            <div className="col-md-9">
                                <i className="far fa-clock small mr-1"/>{asDateString(timestamp)}
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardBlock.total')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    <span
                                        className="u-label u-label--value u-label--primary rounded my-1">{asFormatNumber(totalAmountNQT)}</span>
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardBlock.count')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    <Link className="u-label u-label--value u-label--primary rounded my-1"
                                          to={`/txs/${height}`}
                                          title={I18n.t('clickPlaceholder.transaction')}>{I18n.t('cardBlock.numberOfTransactions', {count: numberOfTransactions})}</Link>

                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardBlock.purse')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    <Link className="u-label u-label--value u-label--primary rounded my-1"
                                          to={`/address/${generatorRS}`}
                                          title={I18n.t('clickPlaceholder.address')}>{generatorRS}
                                    </Link>
                                    <UICopy text={generatorRS} alert={I18n.t('copy.address')}/>
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardBlock.generatorPublicKey')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {generatorPublicKey}
                                    <UICopy text={generatorPublicKey} alert={I18n.t('copy.senderPublicKey')}/>
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardBlock.previousBlockHash')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {previousBlockHash}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardBlock.generationSignature')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {generationSignature}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    Payload Hash:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {payloadHash}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardBlock.blockSignature')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {blockSignature}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    Payload Length:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {asPayload(payloadLength)}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    Base Target:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {asBaseTarget(baseTarget)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Height);
