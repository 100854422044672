import React from "react";
import {I18n} from "react-redux-i18n";
import "../../../../../node_modules/rc-tabs/assets/index.css";
import Tabs, {TabPane} from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import WrapperTransactionBlockHOC from "../../../../@hooks";
import TransactionsTable from "../../../@common/table/transactions-table";
import BlocksTable from "../../../@common/table/blocks-table";

function Transactions(props) {

    const {
        account,
        transactions,
        blocks,
        getNextTransactions,
        getNextBlocks,
        moreT,
        moreB,
    } = props;

    console.log(props);

    return (
        <Tabs renderTabBar={() => <ScrollableInkTabBar/>} renderTabContent={() => <TabContent/>}>
            <TabPane tab={I18n.t('address.transaction')} key="1">
                <TransactionsTable accountRS={account.accountRS}
                                   transactions={transactions}
                                   getNextTransactions={getNextTransactions}
                                   more={moreT}
                />
            </TabPane>
            <TabPane tab={I18n.t('address.block')} key="2">
                <BlocksTable blocks={blocks}
                             getNextBlocks={getNextBlocks}
                             more={moreB}
                />
            </TabPane>
        </Tabs>

    )
}

export default React.memo(WrapperTransactionBlockHOC(Transactions));
