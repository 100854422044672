import React from "react";
import {I18n} from "react-redux-i18n";
import * as PropTypes from "prop-types";

function Search(props) {

    const {stateSearch, setStateSearch, handleSubmit} = props;

    return (
        <div className="card gradient-half-primary-body-v1">
            <div className="card-body px-sm-4 pb-sm-4 ">
                <form autoComplete="off" id="formSearch" spellCheck="false" onSubmit={handleSubmit}>
                    <div className="d-none d-sm-flex align-items-baseline">
                        <h1 className="h5 text-white">{I18n.t('search.title')}</h1>
                    </div>
                    <div className="input-group input-group-main">
                        <input id="txtSearchInput"
                               type="text"
                               className="form-control"
                               placeholder={I18n.t('search.placeholder')}
                               value={stateSearch}
                               onChange={({target}) => setStateSearch(target.value)}
                               name="q"/>
                        <input type="hidden" id="hdnSearchText"/>
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="submit" aria-label="Search">
                                <span className="d-none d-sm-inline-block">{I18n.t('search.button')}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

Search.propTypes = {
    stateSearch: PropTypes.string,
    setStateSearch: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
};

export default Search;
