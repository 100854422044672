import React from "react";
import {Link} from "react-router-dom";
import {I18n} from "react-redux-i18n";
import {asDataTime, asFormatNumber} from "../../../../@helper";

function LeftSideBar(props) {

    const {blocks} = props;
    const countItem = blocks.length;

    return (
        <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="card h-100">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h2 className="card-header-title">{I18n.t('home.latest.title')}</h2>
                </div>
                <section>
                    <div className="js-scrollbar card-body">
                        {blocks.map((block, idx) => {
                            return (
                                <div key={idx}>
                                    <div className="row">
                                        <div className='col-sm-5'>
                                            <div className='media align-items-sm-center mr-4 mb-1 mb-sm-0'>
                                                <div className='d-none d-sm-flex mr-2'>
                                                    <span className='btn btn-icon btn-soft-secondary'>
                                                        <span className='btn-icon__inner text-dark'>Bk</span>
                                                    </span>
                                                </div>
                                                <div className="media-body">
                                                    <span className='d-inline-block d-sm-none'>{I18n.t('home.latest.block')}#&nbsp;</span>
                                                    <Link to={`/height/${block.height}`}>{block.height}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-7">
                                            <div className='d-flex justify-content-between'>
                                                <div className="text-nowrap">
                                                    <span className='d-block mb-1 mb-sm-0'>
                                                        <Link className='hash-tag-main' to={`/address/${block.generatorRS}`}>{block.generatorRS}</Link>
                                                    </span>
                                                    <Link to={`/txs/${block.height}`} title="Transactions in this Block">{block.numberOfTransactions} txns </Link>
                                                    <span className="small text-secondary">{asDataTime(block.timestamp)}</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <span className='u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap'
                                                        title='Block Reward'>{asFormatNumber(block.totalAmountNQT)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={countItem === (idx + 1) ? {display: 'none'} : {}}
                                        className='hr-space'/>
                                </div>
                            );
                        })}
                    </div>
                    <div className="card-footer">
                        <Link className="btn btn-xs btn-block btn-soft-primary" to={`/blocks`}>{I18n.t('home.latest.button')}</Link>
                    </div>
                </section>
            </div>
        </div>
    )
};

export default LeftSideBar;
