import React from 'react';
import './@assets/css/custom.css';
import './@assets/css/theme.min.css';
import './@assets/css/font-awesome/css/fontawesome-all.min.css';
import './@assets/css/index.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {connect} from "react-redux";
import {BrowserRouter, Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import AppRouter from "./@routes";
import Header from "./domains/Header";
import Footer from "./domains/Footer";
import {Ripple} from "react-preloaders";
import SearchContainer from "./domains/search/container";
import {ToastContainer} from "react-toastify";
import Banner from "./domains/banner";

const history = createBrowserHistory();

function App(props) {
    const {config, pending} = props;

    return (
        <BrowserRouter>
            <Router history={history}>
                <section className={config.theme}>
                    <div className="wrapper">
                        <Header/>
                        <main id="content" className="bg-light" role="main">
                            <div className="container">
                                <div className="d-flex justify-content-between pt-4  pb-3"/>
                            </div>
                            <div className="container space-bottom-2">
                                <div className="mb-4">
                                    <SearchContainer/>
                                    <Banner config={config} />
                                </div>
                                <Ripple
                                    customLoading={pending}
                                    color={'#fff'}
                                    time={0}
                                    background={'linear-gradient(145deg,#a809ed,#7b00ff)'}
                                />
                                <ToastContainer position="top-right" newestOnTop closeOnClick autoClose={3000}/>
                                {pending ? <div/> : <AppRouter/>}
                            </div>
                        </main>
                        <div id="push">&nbsp;</div>
                    </div>
                    <Footer/>
                </section>
            </Router>
        </BrowserRouter>
    );
}

const mapStateToProps = state => ({
    ...state.app
});

export default connect(mapStateToProps)(React.memo(App));
