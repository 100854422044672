export const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3100/' : 'https://api2.prizmexplorer.com/';
export const THEME = {
    light: "null",
    dark: 'dark-mode'
};

export const LANGUAGE = 'ru';
export const MAIN_TRANSACTIONS_LAST_INDEX = 5;
export const INDEX_PARAMINING_COEFFICIENT = 56;
export const ACCOUNT_GENESIS = 'PRIZM-TE8N-B3VM-JJQH-5NYJB';

export const TYPE_MAIN_STATE = 'main_state';
export const TYPE_MAIN_PENDING_STATE = 'main_pending_state';

export const TYPE_APP_STATE = 'app_state';
export const TYPE_APP_THEME_STATE = 'app_theme_state';
export const TYPE_APP_PENDING_STATE = 'app_pending_state';

export const TYPE_ACCOUNT_STATE = 'account_state';
export const TYPE_ACCOUNT_RESET_STATE = 'account_reset_state';
export const TYPE_ACCOUNT_PENDING_STATE = 'account_pending_state';

export const TYPE_BLOCKS_STATE = 'blocks_state';
export const TYPE_BLOCKS_PENDING_STATE = 'blocks_pending_state';

export const TYPE_TX_STATE = 'tx_state';
export const TYPE_TX_PENDING_STATE = 'tx_pending_state';

export const TYPE_TXS_STATE = 'txs_state';
export const TYPE_TXS_PENDING_STATE = 'txs_pending_state';

export const TYPE_HEIGHT_STATE = 'height_state';
export const TYPE_HEIGHT_PENDING_STATE = 'height_pending_state';



