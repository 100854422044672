import React from "react";
import BlocksTable from "../@common/table/blocks-table";
import WrapperTransactionBlockHOC from "../../@hooks";
import * as PropTypes from "prop-types";
import UIPreloader from "../@common/preloader";

function Blocks(props) {

    const {blocks: {blocks, pending}, getBlocks} = props;

    return (
        <>
            <UIPreloader enable={pending}/>
            <BlocksTable blocks={blocks} getNextBlocks={getBlocks}/>
        </>
    )
}

Blocks.propTypes = {
    blocks: PropTypes.shape({
        blocks: PropTypes.array,
        pending: PropTypes.bool,
    }),
    getBlocks: PropTypes.func,
};

Blocks.defaultProps = {
    blocks: [],
    getBlocks: () => {
    }
};

export default (React.memo(WrapperTransactionBlockHOC(Blocks)));
