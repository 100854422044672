import React from "react";
import * as PropTypes from "prop-types";
import UIPreloader from "../@common/preloader";
import {I18n} from "react-redux-i18n";
import {Link} from "react-router-dom";
import UICopy from "../@common/copy";
import {asDateString, asFormatNumber} from "../../@helper";

function Tx(props) {

    const {
        tx: {
            ecBlockHeight,
            block,
            timestamp,
            amountNQT,
            feeNQT,
            recipientRS,
            senderRS,
            confirmations,
            senderPublicKey,
            signature,
            signatureHash,
            fullHash,
            transaction,
        },
        pending,
    } = props;

    return (
        <div className="card">
            <div className="tab-content">
                <div className="tab-pane fade active show" id="overview" role="tabpanel" aria-labelledby="home-tab">
                    <UIPreloader enable={pending}/>
                    <div id="ContentPlaceHolder1_maintable" className="card-body">
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.heightBlock')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    <Link className="u-label u-label--value u-label--primary rounded my-1"
                                        to={`/height/${ecBlockHeight}`}
                                        title={I18n.t('clickPlaceholder.heightBlock')}>{ecBlockHeight}
                                    </Link>
                                    <UICopy text={ecBlockHeight} alert={I18n.t('copy.heightBlock')}/>
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space mt-2"/>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.transaction')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {transaction}
                                    <UICopy text={transaction} alert={I18n.t('copy.transaction')}/>
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space mt-2"/>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.block')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {block}
                                    <UICopy text={block} alert={I18n.t('copy.block')}/>
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space mt-2"/>
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                {I18n.t('cardTx.createdAt')}:
                            </div>
                            <div className="col-md-9">
                                <i className="far fa-clock small mr-1" />{asDateString(timestamp)}
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.sum')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {asFormatNumber(amountNQT)}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.fee')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {asFormatNumber(feeNQT)}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.confirmations')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {confirmations}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.recipient')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    <Link
                                        className="u-label u-label--value u-label--primary rounded my-1"
                                        to={`/address/${recipientRS}`}
                                        title={I18n.t('clickPlaceholder.address')}>{recipientRS}
                                    </Link>
                                    <UICopy text={recipientRS} alert={I18n.t('copy.address')}/>
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.sender')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    <Link
                                        className="u-label u-label--value u-label--primary rounded my-1"
                                        to={`/address/${senderRS}`}
                                        title={I18n.t('clickPlaceholder.address')}>{senderRS}
                                    </Link>
                                    <UICopy text={senderRS} alert={I18n.t('copy.address')}/>
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.senderPublicKey')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {senderPublicKey}
                                    <UICopy text={senderPublicKey} alert={I18n.t('copy.senderPublicKey')}/>
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.signature')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {signature}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.signatureHash')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {signatureHash}
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2"/>
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    {I18n.t('cardTx.fullHash')}:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    {fullHash}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Tx.propTypes = {
    tx: PropTypes.object,
    pending: PropTypes.bool,
};

export default React.memo(Tx);
