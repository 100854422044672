import {TYPE_TX_PENDING_STATE, TYPE_TX_STATE} from "../../constants";

const txDefaultState = {
    tx: {
        ecBlockHeight: '',
        block: '',
        timestamp: 0,
        amountNQT: 0,
        feeNQT: 0,
        recipientRS: '',
        senderRS: '',
        confirmations: 0,
        senderPublicKey: '',
        signature: '',
        signatureHash: '',
        fullHash: '',
    },
    pending: true,
};

export const txReducer = (state = txDefaultState, action) => {
    switch (action.type) {
        case TYPE_TX_STATE :
            return {
                ...state,
                tx: action.payload,
                pending: false,
            };

        case TYPE_TX_PENDING_STATE :
            return {
                ...state,
                pending: action.pending,
            };
        default :
            return state;
    }
};
