import {TYPE_MAIN_PENDING_STATE, TYPE_MAIN_STATE} from "../../constants";

const mainDefaultState = {
    transactionsPerHour: 0,
    heightPerHour: 0,
    blockGenerationTime: 0 + ' s',
    avgNumberPerBlock: 0,
    blocks: [],
    transactions: [],
    pending: true,
};

export const mainReducer = (state = mainDefaultState, action) => {
    switch (action.type) {
        case TYPE_MAIN_STATE :
            return {
                ...state,
                ...action.payload,
                pending: action.pending,
            };

        case TYPE_MAIN_PENDING_STATE :
            return {
                ...state,
                pending: action.pending,
            };
        default:
            return state;
    }
};
