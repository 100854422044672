import {TYPE_BLOCKS_PENDING_STATE, TYPE_BLOCKS_STATE} from "../../constants";
import {getBlocks} from "../../@api";

export function fetchBlocks(page = 1) {
    return async (dispatch) => {
        const firstIndex = (page - 1) * 100;
        dispatch(pending());
        return await getBlocks({includeTransactions: false, firstIndex, lastIndex: firstIndex + 100})
            .then(response => {
                const {blocks} = response.data;
                dispatch(setBlocksState(blocks))
            }).finally(() => dispatch(pending(false)))
    }
}

const setBlocksState = blocks => ({
    type: TYPE_BLOCKS_STATE,
    payload: blocks,
});

const pending = (pending = true) => ({
    type: TYPE_BLOCKS_PENDING_STATE,
    pending: pending,
});
