import React, {useEffect, memo} from "react";
import Main from "./index";
import {connect} from "react-redux";
import {fetchMainData} from "../../store/main/action";

const MainContainer = memo((props) => {

    useEffect(() => {
        props.fetchData();
    }, []);

    return <Main {...props.main} numberOfBlocks={props.numberOfBlocks}/>
});

const mapStateToProps = state => ({
    main: state.main,
    numberOfBlocks: state.app.getState.numberOfBlocks,
});
const mapDispatchTopProps = dispatch => ({
    fetchData: () => dispatch(fetchMainData())
});

export default connect(mapStateToProps, mapDispatchTopProps)(React.memo(MainContainer));
