import React from "react";
import {store} from "../store/createStore";
import {INDEX_PARAMINING_COEFFICIENT} from "../constants";
import Para from "./prizmparacalc";

export function asFormatNumber(data, divideBy100 = true) {
    let result = data;
    if (divideBy100) {
        result = (data / 100);
    }

    result = new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(result);

    return result === '0' ? Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(data) : result;
}

export function asDataTime(time) {
    const dateTime = new Date(asTimestamp(time));
    const startTime = new Date();

    let seconds = Math.floor((startTime.getTime() - dateTime.getTime()) / 1000);
    const day = Math.floor(seconds / 60 / 60 / 24);
    const hour = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hour * 3600)) / 60);

    let result = 0;

    if (seconds >= 0 && seconds <= 60) {
        result = seconds + ' secs ago';
    } else if (hour <= 0 && minutes <= 60) {
        const sec = seconds - (minutes * 60);
        if (sec > 0) {
            result = minutes + '  min ' + sec + ' secs ago';
        } else {
            result = minutes + '  min ago';
        }

    } else if (hour >= 1 && hour <= 24) {
        result = hour + ' hr ' + minutes + ' mins ago';
    } else if (day > 0) {
        result = day + ' day ' + (hour % 24) + ' hr ' + minutes + ' mins ago';
    }

    return result;
}

export function asTimestamp(time) {
    const {epochBeginning} = store.getState().app.getConstants;
    return time * 1000 + epochBeginning - 500;
}

export function asDateString(time) {
    const dateTime = new Date(asTimestamp(time));

    return dateTime.toLocaleString('ru');
}

export function encodeQueryData(url, params = {}) {
    const ret = [];
    for (let d in params)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(params[d]));
    if (ret.length) {
        return url + '&' + ret.join('&');
    }
    return url;
}

export function setParamining(dataPareAccount) {

    const paramining = {
        profitperday: 0,
        profitpermonth: 0,
        profitperyear: 0,
        paramining: null,
        paraminingday: null,
        balancestructure: 0,
        paraminingpercent: 0.0000,
    };

    if (dataPareAccount.multiplier > 0) {

        let index;
        if (dataPareAccount.balance / 100 < 100) index = ((100 - INDEX_PARAMINING_COEFFICIENT) / 100) * 0.0012;
        else if (dataPareAccount.balance / 100 < 1000) index = ((100 - INDEX_PARAMINING_COEFFICIENT) / 100) * 0.0014;
        else if (dataPareAccount.balance / 100 < 10000) index = ((100 - INDEX_PARAMINING_COEFFICIENT) / 100) * 0.0018;
        else if (dataPareAccount.balance / 100 < 50000) index = ((100 - INDEX_PARAMINING_COEFFICIENT) / 100) * 0.0021;
        else if (dataPareAccount.balance / 100 < 100000) index = ((100 - INDEX_PARAMINING_COEFFICIENT) / 100) * 0.0025;
        else if (dataPareAccount.balance / 100 < 500000) index = ((100 - INDEX_PARAMINING_COEFFICIENT) / 100) * 0.0028;
        else if (dataPareAccount.balance / 100 < 1000000) index = ((100 - INDEX_PARAMINING_COEFFICIENT) / 100) * 0.0033;
        else index = 0;

        let coefficient;
        if (dataPareAccount.amount / 100 < 1000) coefficient = 1;
        else if (dataPareAccount.amount / 100 < 10000) coefficient = 2.18;
        else if (dataPareAccount.amount / 100 < 100000) coefficient = 2.36;
        else if (dataPareAccount.amount / 100 < 1000000) coefficient = 2.77;
        else if (dataPareAccount.amount / 100 < 10000000) coefficient = 3.05;
        else if (dataPareAccount.amount / 100 < 100000000) coefficient = 3.36;
        else if (dataPareAccount.amount / 100 < 1000000000) coefficient = 3.88;
        else coefficient = 4.37;

        let mnoz = index * coefficient;
        let mnoz30 = Math.pow(1 + mnoz / 24, 30 * 24) - 1;
        let mnoz365 = Math.pow(1 + mnoz / 24, 365 * 24) - 1;

        paramining.profitperday = dataPareAccount.balance / 100 * mnoz;
        paramining.profitpermonth = dataPareAccount.balance / 100 * mnoz30;
        paramining.profitperyear = dataPareAccount.balance / 100 * mnoz365;
        paramining.balancestructure = dataPareAccount.amount;
        paramining.paraminingpercent = (mnoz * 100);

        if (dataPareAccount.last > 0) {
            const {unconfirmedBalanceNQT} = store.getState().app.genesis;

            const calc = new Para();
            let paraDaily = 0;
            const balance = new Number(dataPareAccount.balance);
            const amount = new Number(dataPareAccount.amount);
            const last = new Number(dataPareAccount.last);
            const genesisBalance = new Number(unconfirmedBalanceNQT);

            paramining.paramining = () => {
                return calc.calc(balance, amount, last, genesisBalance).toFixed(7);
            };

            paramining.paraminingday = () => {
                const payout = calc.calc(balance, amount, last, genesisBalance);
                const payout2 = calc.calc(balance, amount, last - 86400, genesisBalance);

                if (payout2 > payout) {
                    paraDaily = payout2 - payout;
                }

                return paraDaily.toFixed(7);
            };
        }

    }

    return paramining;
}

export function asPayload(payloadLength) {
    if (payloadLength < 1000) {
        return payloadLength + ' B';
    } else {
        return Math.floor(payloadLength / 1000) + ' KB';
    }
}

export function asBaseTarget(baseTarget) {
    let size = Math.round(baseTarget / 153722867 * 100).toString();

    if (size.length <= 4) {
        size = size.substr(0, 2);
    } else if (size.length > 4) {
        size = size.substr(0, 3);
    }

    while (size.length < 4) {
        size = "0" + size;
    }
    return size + ' %';
}
