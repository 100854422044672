import { createStore, applyMiddleware } from 'redux'
import thunk from "redux-thunk"
import rootReducers from "./rootRuducer";
import { loadTranslations, syncTranslationWithStore } from 'react-redux-i18n';
import {translationsObject} from "../@i18n";
import {fetchInitDataApp, setStateAppTheme} from "./app/action";
import {fetchMainData} from "./main/action";

export const store = createStore(rootReducers, applyMiddleware(thunk));

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setStateAppTheme(localStorage.getItem('theme')));
store.dispatch(fetchInitDataApp());
store.dispatch(fetchMainData());

