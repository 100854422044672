import {TYPE_HEIGHT_PENDING_STATE, TYPE_HEIGHT_STATE} from "../../constants";
import {getBlockHeight} from "../../@api";
import {toast} from "react-toastify";
import {I18n} from "react-redux-i18n";

export function fetchHeight(height) {
    return async (dispatch) => {
        dispatch(pending());
        return await getBlockHeight(height)
            .then(response => {
                const result = response.data;
                if (result['errorDescription'] != null) {
                    toast.info(I18n.t('errorNextHeight'));
                } else {
                    dispatch(setHeightState(result));
                }
            })
            .finally(() => dispatch(pending(false)))
    }
}

const setHeightState = data => ({
    type: TYPE_HEIGHT_STATE,
    payload: data,
});

const pending = (pending = true) => ({
    type: TYPE_HEIGHT_PENDING_STATE,
    pending: pending,
});
