import React, {useEffect} from "react";
import {connect} from "react-redux";
import {setLocale} from "react-redux-i18n";
import {changeTheme} from "../store/app/action";
import {LANGUAGE, THEME} from "../constants";
import {I18n} from "react-redux-i18n";
import "../../node_modules/flag-icon-css/css/flag-icon.min.css";

function Footer(props) {

    const {config, setLanguage, onChangeTheme} = props;
    const lang = localStorage.getItem('language') == null ? 'ru' : localStorage.getItem('language');

    function toggleSetLocation(language) {
        setLanguage(language);
        localStorage.setItem('language', language);
    }

    useEffect(() => {
        toggleSetLocation(lang);
    }, [config.language]);

    return (
        <footer className="gradient-half-primary-body-v1 py-4">
            <div className="container">
                <div className="row justify-content-md-between font-size-1 py-3"
                     style={{background: `url('../@assets/images/world-map-white.png') no-repeat 0 30%`}}>
                    <div className="col-lg-3 mb-4 mb-lg-0">
                        <div className="d-flex h-100 flex-column">
                            <div className="d-flex align-items-center mb-4">
                                <span className="h4 text-white mb-0 ml-3">Developed by <a
                                    href="https://bit.team/">BIT.TEAM</a></span>
                            </div>
                            <p className="text-white">
                                Simple and informative prizm (pzm) eplorer developed by our team for prizm community.
                                Bit.team is a group of blockchain developers who made:&nbsp;
                                <a className="text-white" href={process.env.REACT_APP_P2P_BIT_TEAM_URL} target="_blank">p2p.bit.team</a>&nbsp;
                                <a className="text-white"  href={process.env.REACT_APP_SPOT_BIT_TEAM_URL} target="_blank">spot.bit.team</a>&nbsp;
                                <a className="text-white"  href={process.env.REACT_APP_NFT_BIT_TEAM_URL} target="_blank">nft.bit.team</a>&nbsp;
                                <a className="text-white"  href={process.env.REACT_APP_COINMARKETRATE_URL} target="_blank">coinmarketrate.com</a>&nbsp;
                            </p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mb-4 mb-lg-0">
                        <h4 className="h6 text-white heading-border">{I18n.t('footer.col_title_products')}</h4>
                        <ul className="list-group list-group-sm list-group-transparent list-group-white list-group-flush list-group-borderless mb-0">
                            <li><a className="list-group-item" href={process.env.REACT_APP_P2P_BIT_TEAM_URL} target="_blank">p2p.bit.team</a></li>
                            <li><a className="list-group-item" href={process.env.REACT_APP_SPOT_BIT_TEAM_URL} target="_blank">spot.bit.team</a></li>
                            <li><a className="list-group-item" href={process.env.REACT_APP_NFT_BIT_TEAM_URL} target="_blank">nft.bit.team</a></li>
                            <li><a className="list-group-item" href={process.env.REACT_APP_COINMARKETRATE_URL} target="_blank">coinmarketrate.com</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mb-4 mb-lg-0">
                        <h4 className="h6 text-white heading-border">{I18n.t('footer.col_title_news')}</h4>
                        <ul className="list-group list-group-sm list-group-transparent list-group-white list-group-flush list-group-borderless mb-0">
                            <li><a className="list-group-item" href={process.env.REACT_APP_NEWS_BIT_TEAM_URL} target="_blank">news.bit.team</a></li>
                            <li><a className="list-group-item" href={process.env.REACT_APP_NEWS_DECIMAL_URL} target="_blank">decimal.news</a></li>
                            <li><a className="list-group-item" href={process.env.REACT_APP_NEWS_SPACEBOT_URL} target="_blank">spacebot.ltd/news</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-lg-2 mb-4 mb-lg-0 d-none d-md-block">
                        {/*<h4 className="h6 text-white heading-border">Social Links</h4>*/}
                    </div>
                </div>
                <hr className="opacity-md"/>
                <div className="row justify-content-between align-items-center font-size-1">
                    <div className="col-md-3 mb-3 mb-md-0">
                        <p className="mb-0 text-white" style={{display: 'inline-block', marginRight: '20px'}}>
                            Prizm © 2019 (C)
                        </p>
                        <button id="darkModaBtn"
                                type="button"
                                className="btn btn-sm btn-icon btn-soft-light"
                                onClick={onChangeTheme}>
                            <i id="darkModaBtnIcon"
                               className={`fa ${config.theme === THEME.dark ? 'fa-sun' : 'fa-moon'}`}></i>
                        </button>
                        <span
                            className={`lang-button flag-icon ${lang === 'ru' ? 'flag-icon-us' : 'flag-icon-ru'}`}
                            onClick={() => toggleSetLocation(lang === LANGUAGE ? 'us' : 'ru')}/>
                    </div>
                    <div className="col-md-6">

                    </div>
                </div>

            </div>
        </footer>
    )
}

const mapStateToProps = state => ({
    ...state.i18n,
    ...state.app,
});

const mapDispatchTopProps = dispatch => ({
    setLanguage: (locate) => dispatch(setLocale(locate)),
    onChangeTheme: () => dispatch(changeTheme())
});

export default connect(mapStateToProps, mapDispatchTopProps)(Footer);
