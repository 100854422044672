import {TYPE_TX_PENDING_STATE, TYPE_TX_STATE} from "../../constants";
import {getTransaction} from "../../@api";

export function fetchTx(tx) {
    return async (dispatch) => {
        dispatch(pending());
       return await getTransaction(tx)
            .then(response => {
                const transaction = response.data;
                dispatch(setTxState(transaction))
            }).finally(() => dispatch(pending(false)))
    }
}

const setTxState = transactions => ({
    type: TYPE_TX_STATE,
    payload: transactions,
});

const pending = (pending = true) => ({
    type: TYPE_TX_PENDING_STATE,
    pending: pending,
});
