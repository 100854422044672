import axios from "axios";
import {API_URL} from "../constants";
import {toast} from "react-toastify";

const client = axios.create({
    baseURL: API_URL,
    timeout: 25000,
});

client.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.url = normalizationUrl(config.url);
    return config;
}, function (error) {
    // Do something with request error

    return Promise.reject(error);
});

client.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log(error.response);
    const obj = error.toJSON();
    if (obj['message'] != null) {
        toast.error(obj.message, {toastId: 'error'});
    }

    return Promise.reject(error);
});

const normalizationUrl = (url) => {
    if (url.indexOf('getState') !== -1 || url.indexOf('getConstants') !== -1) {
        return url;
    } else {
        return '/prizm?requestType=' + url;
    }
};

export default client;
