import {TYPE_TXS_PENDING_STATE, TYPE_TXS_STATE} from "../../constants";

const txsDefaultState = {
    transactions: [],
    pending: true,
};

export const txsReducer = (state = txsDefaultState, action) => {
    switch (action.type) {
        case TYPE_TXS_STATE :
            return {
                ...state,
                transactions: action.payload,
                pending: false,
            };

        case TYPE_TXS_PENDING_STATE :
            return {
                ...state,
                pending: action.pending,
            };
        default :
            return state;
    }
};
