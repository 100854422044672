import React from "react";
import {I18n} from "react-redux-i18n";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {asBaseTarget, asDataTime, asDateString, asFormatNumber, asPayload} from "../../../@helper";
import Table from "./index";
import UIPagination from "../pagination";

function BlocksTable(props) {

    const {blocks, getNextBlocks, more} = props;

    const columns = React.useMemo(() => [
        {
            Header: () => I18n.t('table.height'),
            accessor: 'height',
            Cell: ({row}) => {
                const {values} = row;
                return <Link to={`/height/${values.height}`}>{values.height}</Link>
            }
        },
        {
            Header: () => I18n.t('table.time'),
            accessor: 'timestamp',
            Cell: ({row}) => {
                const {values} = row;
                return <span className="time" rel="tooltip" data-placement="bottom" title={asDateString(values.timestamp)}>{asDataTime(values.timestamp)}</span>;
            }
        },
        {
            Header: () => I18n.t('table.transaction'),
            accessor: 'numberOfTransactions',
            Cell: ({row}) => {
                const {values} = row;
                return <Link to={`/txs/${values.height}`}>{values.numberOfTransactions}</Link>
            }
        },
        {
            Header: () => I18n.t('table.sum'),
            accessor: 'totalAmountNQT',
            Cell: ({row}) => {
                const {values} = row;
                return <span>{asFormatNumber(values.totalAmountNQT)}</span>;

            }
        },
        {
            Header: () => I18n.t('table.fee'),
            accessor: 'totalFeeNQT',
            Cell: ({row}) => {
                const {values} = row;
                return <span>{asFormatNumber(values.totalFeeNQT)}</span>;
            }
        },
        {
            Header: () => I18n.t('table.purse'),
            accessor: 'generatorRS',
            Cell: ({row}) => {
                const {values} = row;
                return <Link to={`/address/${values.generatorRS}`}>{values.generatorRS}</Link>;
            }
        },
        {
            Header: 'Payload',
            accessor: 'payloadLength',
            Cell: ({row}) => {
                const {values} = row;
                return asPayload(values.payloadLength);
            }
        },
        {
            Header: () => I18n.t('table.baseGoal'),
            accessor: 'baseTarget',
            Cell: ({row}) => {
                const {values} = row;
                return asBaseTarget(values.baseTarget);
            }
        },
    ], []);

    return (
        <>
            <Table columns={columns} data={blocks}/>
            {more === true ? <UIPagination onChangeNextPage={getNextBlocks}/> : null}
        </>
    )
}

BlocksTable.propTypes = {
    blocks: PropTypes.array,
    getNextBlocks: PropTypes.func,
    more: PropTypes.bool,
};

BlocksTable.defaultProps = {
    blocks: [],
    getNextBlocks: () => {
    },
    more: true,
};

export default React.memo(BlocksTable);
