import React from "react";

function WrapperTransactionBlockHOC(Component) {
    return class Class extends React.Component{
        render() {
            return (
                <div className="card">
                    <div className="card-body ">
                        <div className="table-responsive mb-2 mb-md-0">
                            <Component {...this.props}/>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default WrapperTransactionBlockHOC;
