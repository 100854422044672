import React, {useEffect} from "react";
import {connect} from "react-redux";
import Txs from "./index"
import {Element, scroller} from 'react-scroll';
import {useHistory} from "react-router-dom";
import {fetchTxs} from "../../store/txs/action";

function TxsContainer(props) {

    const history = useHistory();
    const {height} = props.match.params;

    function onNextPageHandel() {
        const nextHeight = parseInt(height) - 1;
        history.push(`/txs/${nextHeight}`);
        props.getTxs(nextHeight);
        scrollToTop();
    }

    function scrollToTop() {
        scroller.scrollTo('scrollToElementTxs', {
            smooth: true,
        })
    }

    useEffect(() => {
        scrollToTop();
        props.getTxs(height);
    }, []);

    return (
        <Element name="scrollToElementTxs">
            <Txs txs={props.txs} getTxs={onNextPageHandel}/>
        </Element>
    )
}

const mapStateToProps = state => ({
    txs: state.txs,
});

const mapDispatchTopProps = dispatch => ({
    getTxs: (height) => dispatch(fetchTxs(height)),
});

export default connect(mapStateToProps, mapDispatchTopProps)(React.memo(TxsContainer));
