import {TYPE_TXS_PENDING_STATE, TYPE_TXS_STATE} from "../../constants";
import {getBlockHeight, getBlocksTransactions} from "../../@api";

export function fetchTxs(height) {
    return (dispatch) => {
        dispatch(pending());
        getBlockHeight(height,{includeTransactions: true})
            .then(response => {
                const {transactions} = response.data;
                dispatch(setTxsState(transactions))
            }).finally(() => dispatch(pending(false)))
    }
}

const setTxsState = transactions => ({
    type: TYPE_TXS_STATE,
    payload: transactions,
});

const pending = (pending = true) => ({
    type: TYPE_TXS_PENDING_STATE,
    pending: pending,
});
