import {combineReducers} from 'redux';
import {i18nReducer} from "react-redux-i18n";
import {appReducer} from "./app/reducer";
import {mainReducer} from "./main/reducer";
import {addressReducer} from "./address/reducer";
import {blocksReducer} from "./blocks/reducer";
import {txsReducer} from "./txs/reducer";
import {heightReducer} from "./height/reducer";
import {txReducer} from "./tx/reducer";

export default combineReducers({
    app: appReducer,
    main: mainReducer,
    address: addressReducer,
    blocks: blocksReducer,
    tx: txReducer,
    txs: txsReducer,
    height: heightReducer,
    i18n: i18nReducer,
});
