import React from "react";
import UICopy from "../../@common/copy";
import {asFormatNumber} from "../../../@helper";
import {I18n} from "react-redux-i18n";
import classnames from "classnames";
import * as PropTypes from "prop-types";

function InfoAddress(props) {
    const {
        account: {
            name,
            description,
            accountRS,
            publicKey,
            balanceNQT,
            forgedBalanceNQT,
        }
    } = props;

    const classesName = classnames({'enable-div': !name.length});
    const classesDescription = classnames({'enable-div': !description.length});

    return (
        <div className="row mb-4">
            <div className="col-md-12">
                <div className="card h-100">
                    <div className="card-body">
                        <div className={classesName}>
                            <div className="row align-items-center">
                                <div className="col-md-12" style={{textAlign: 'center', fontSize: '18px'}}>
                                    {name}
                                </div>
                            </div>
                            <hr className="hr-space"/>
                        </div>
                        <div className={classesDescription}>
                            <div className="row align-items-center">
                                <div className="col-md-12" style={{textAlign: 'center'}}>
                                    {description}
                                </div>
                            </div>
                            <hr className="hr-space"/>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-5 mb-1 mb-md-0">{I18n.t('address.purse')}:</div>
                            <div className="col-md-6">
                                {accountRS}&nbsp;
                                <UICopy text={accountRS} alert={'Address copied'}/>
                            </div>
                        </div>
                        <hr className="hr-space"/>
                        <div className="row align-items-center">
                            <div className="col-md-5 mb-1 mb-md-0">{I18n.t('address.publicKey')}:</div>
                            <div className="col-md-6">{publicKey}</div>
                        </div>
                        <hr className="hr-space"/>
                        <div className="row align-items-center">
                            <div className="col-md-5 mb-1 mb-md-0">{I18n.t('address.balanceNQT')}:</div>
                            <div className="col-md-6">
                                    <span className="u-label u-label--value u-label--primary">
                                        {asFormatNumber(balanceNQT)}
                                    </span>
                            </div>
                        </div>
                        <hr className="hr-space"/>
                        <div className="row align-items-center">
                            <div className="col-md-5 mb-1 mb-md-0">{I18n.t('address.forged')}:</div>
                            <div className="col-md-6"><span
                                className="u-label u-label--value u-label--primary">
                                    {asFormatNumber(forgedBalanceNQT)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

InfoAddress.propTypes = {
    account: PropTypes.object.isRequired
};

export default React.memo(InfoAddress);
