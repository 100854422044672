import React from "react";
import {I18n} from "react-redux-i18n";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {asDataTime, asDateString, asFormatNumber} from "../../../@helper";
import {ACCOUNT_GENESIS} from "../../../constants";
import Table from "./index";
import UIPagination from "../pagination";

function TransactionsTable(props) {

    const {accountRS, transactions, getNextTransactions, more} = props;

    const columns = React.useMemo(() => [
        {
            Header: () => I18n.t('table.height'),
            accessor: 'height',
            Cell: ({row}) => {
                const {values} = row;
                return <Link to={`/height/${values.height}`}>{values.height}</Link>
            }
        },
        {
            Header: () => I18n.t('table.transaction'),
            accessor: 'transaction',
            Cell: ({row}) => {
                const {values} = row;
                return <Link to={`/tx/${values.transaction}`}>{values.transaction}</Link>
            }
        },
        {
            Header: () => I18n.t('table.time'),
            accessor: 'timestamp',
            Cell: ({row}) => {
                const {values} = row;
                return <span className="time" rel="tooltip" data-placement="bottom" title={asDateString(values.timestamp)}>{asDataTime(values.timestamp)}</span>;
            }
        },
        {
            Header: () => I18n.t('table.sum'),
            accessor: 'amountNQT',
            Cell: ({row}) => {
                const {values} = row;
                if (values.senderRS === accountRS) {
                    return <span><i className="fa fa-minus-circle take-off-icon"
                                    aria-hidden="true"/>&nbsp;{asFormatNumber(values.amountNQT)}</span>;
                } else {
                    return <span><i className="fa fa-plus-circle enrollment-icon"
                                    aria-hidden="true"/>&nbsp;{asFormatNumber(values.amountNQT)}</span>;
                }
            }
        },
        {
            Header: () => I18n.t('table.fee'),
            accessor: 'feeNQT',
            Cell: ({row}) => {
                const {values} = row;
                return asFormatNumber(values.feeNQT);
            }
        },
        {
            Header: () => I18n.t('table.from'),
            accessor: 'senderRS',
            Cell: ({row}) => {
                const {values} = row;
                if (values.senderRS === ACCOUNT_GENESIS) {
                    return <span>paramining</span>;
                } else if (values.senderRS === accountRS) {
                    return I18n.t('table.your');
                } else {
                    return <Link to={`/address/${values.senderRS}`}>{values.senderRS}</Link>;
                }
            }
        },
        {
            Header: () => I18n.t('table.to'),
            accessor: 'recipientRS',
            Cell: ({row}) => {
                const {values} = row;
                if (values.recipientRS === ACCOUNT_GENESIS) {
                    return <span>paramining</span>;
                } else if (values.recipientRS === accountRS) {
                    return I18n.t('table.yours');
                } else {
                    return <Link to={`/address/${values.recipientRS}`}>{values.recipientRS}</Link>;
                }
            }
        },
    ], [accountRS]);

    return (
        <>
            <Table columns={columns} data={transactions}/>
            {more === true ? <UIPagination onChangeNextPage={getNextTransactions}/> : null}
        </>
    )
}

TransactionsTable.propTypes = {
    accountRS: PropTypes.string,
    transactions: PropTypes.array,
    getNextTransactions: PropTypes.func,
    more: PropTypes.bool,
};

TransactionsTable.defaultProps = {
    accountRS: null,
    transactions: [],
    getNextTransactions: () => {
    },
    more: true,
};

export default React.memo(TransactionsTable);
