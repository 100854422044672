import React, {useEffect, useState} from "react";
import {I18n} from "react-redux-i18n";
import {asFormatNumber} from "../../../@helper";
import * as PropTypes from "prop-types";

function Paramining(props) {

    const {
        paramining: {
            profitperday,
            profitpermonth,
            profitperyear,
            paramining,
            paraminingday,
            balancestructure,
            paraminingpercent,
        }
    } = props;

    return (
        <div id="ContentPlaceHolder1_mainboxes" className="card mb-4">
            <div className="card-body">
                <div className="row mx-gutters-md-1">
                    <div className="col-md-6 col-lg-3 u-ver-divider u-ver-divider--none-md">
                        <div className="media align-items-center">
                            <figure className="u-sm-avatar mr-2">
                                <div className="u-xs-avatar mx-auto">
                                    <i className="fa fa-calendar fa-2x" aria-hidden="true"/>
                                </div>
                            </figure>
                            <div className="media-body">
                                <h2 className="font-size-1 text-uppercase mb-0">
                                    {I18n.t('address.paramining.dayIncome')}
                                </h2>
                                <span
                                    className="text-secondary">{asFormatNumber(profitperday, false)}</span>
                            </div>
                        </div>
                        <hr className="hr-space-lg"/>
                    </div>
                    <div className="col-md-6 col-lg-3 u-ver-divider u-ver-divider--none-md">
                        <div className="media align-items-center">
                            <figure className="u-sm-avatar mr-2">
                                <div className="u-xs-avatar mx-auto">
                                    <i className="fa fa-calendar fa-2x" aria-hidden="true"/>
                                </div>
                            </figure>
                            <div className="media-body">
                                <h2 className="font-size-1 text-uppercase mb-0">
                                    {I18n.t('address.paramining.monthIncome')}
                                </h2>
                                <span
                                    className="text-secondary">{asFormatNumber(profitpermonth, false)}</span>
                            </div>
                        </div>
                        <hr className="hr-space-lg"/>
                    </div>
                    <div className="col-md-6 col-lg-3 u-ver-divider u-ver-divider--none-md">
                        <div className="media align-items-center">
                            <figure className="u-sm-avatar mr-2">
                                <div className="u-xs-avatar mx-auto">
                                    <i className="fa fa-calendar fa-2x" aria-hidden="true"/>
                                </div>
                            </figure>
                            <div className="media-body">
                                <h2 className="font-size-1 text-uppercase mb-0">
                                    {I18n.t('address.paramining.yearIncome')}
                                </h2>
                                <span
                                    className="text-secondary">{asFormatNumber(profitperyear, false)}</span>
                            </div>
                        </div>
                        <hr className="hr-space-lg"/>
                    </div>
                    <div className="col-md-6 col-lg-3 u-ver-divider u-ver-divider--none-md">
                        <div className="media align-items-center">
                            <figure className="u-sm-avatar mr-2">
                                <div className="u-xs-avatar mx-auto">
                                    <i className="fa fa-sitemap fa-2x" aria-hidden="true"/>
                                </div>
                            </figure>
                            <div className="media-body">
                                <h2 className="font-size-1 text-uppercase mb-0">
                                    {I18n.t('address.paramining.structure')}
                                </h2>
                                <span
                                    className="text-secondary">{asFormatNumber(balancestructure)}</span>
                            </div>
                        </div>
                        <hr className="hr-space-lg"/>
                    </div>
                    <div className="col-md-6 col-lg-4 u-ver-divider u-ver-divider--none-md">
                        <div className="media align-items-center">
                            <figure className="u-sm-avatar mr-2">
                                <div className="u-xs-avatar mx-auto">
                                    <i className="fa fa-cloud-download fa-2x" aria-hidden="true"/>
                                </div>
                            </figure>
                            <div className="media-body">
                                <h2 className="font-size-1 text-uppercase mb-0">
                                    {I18n.t('address.paramining.paramining')}
                                </h2>
                                <CountParamining paramining={paramining}/>
                            </div>
                        </div>
                        <hr className="hr-space-lg"/>
                    </div>
                    <div className="col-md-6 col-lg-4 u-ver-divider u-ver-divider--none-md">
                        <div className="media align-items-center">
                            <figure className="u-sm-avatar mr-2">
                                <div className="u-xs-avatar mx-auto">
                                    <i className="fa fa-cloud-download fa-2x" aria-hidden="true"/>
                                </div>
                            </figure>
                            <div className="media-body">
                                <h2 className="font-size-1 text-uppercase mb-0">
                                    {I18n.t('address.paramining.paraminingday')}
                                </h2>
                                <CountParaminingDay paraminingday={paraminingday}/>
                            </div>
                        </div>
                        <hr className="hr-space-lg"/>
                    </div>
                    <div className="col-md-6 col-lg-4 u-ver-divider u-ver-divider--none-md">
                        <div className="media align-items-center">
                            <figure className="u-sm-avatar mr-2">
                                <div className="u-xs-avatar mx-auto">
                                    <i className="fa fa-percent fa-2x" aria-hidden="true"/>
                                </div>
                            </figure>
                            <div className="media-body">
                                <h2 className="font-size-1 text-uppercase mb-0">
                                    {I18n.t('address.paramining.percent')}
                                </h2>
                                <span className="text-secondary">{paraminingpercent.toFixed(4)}</span>
                            </div>
                        </div>
                        <hr className="hr-space-lg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CountParamining({paramining}) {

    const [paramaningResult, setParamaningResult] = useState('0.00');

    useEffect(() => {
        if (typeof paramining === "function") {
            const id = setInterval(() => {
                setParamaningResult(() => paramining());
            }, 100);

            return () => clearInterval(id);
        }
    }, [paramining]);

    return <span className="text-secondary">{paramaningResult}</span>
}

function CountParaminingDay({paraminingday}) {

    const [paramaningResult, setParamaningResult] = useState('0.00');

    useEffect(() => {
        if (typeof paraminingday === "function") {
            const id = setInterval(() => {
                setParamaningResult(() => paraminingday());
            }, 100);

            return () => clearInterval(id);
        }
    }, [paraminingday]);

    return <span className="text-secondary">{paramaningResult}</span>
}

Paramining.propTypes = {
    paramining: PropTypes.object.isRequired,
};

export default React.memo(Paramining);
