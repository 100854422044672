export const ru = {
    search: {
        title: 'Prizm Блокчейн',
        placeholder: 'Поиск по Адресу / Тхн / Блоку / Высоты блока',
        button: 'Найти',
        notSearch: 'По вашему запросу ничего не найдено'
    },

    home: {
        nav_link_home: 'Главная',
        block_cont_transaction: 'Транзакций в час',
        block_cont_transaction_hour: 'Блоков в час',
        block_time_created_transaction: 'Время создания блока',
        block_count_average_transaction: 'Среднее количество транзакций в блоке',

        latest: {
            title: 'Последние блоки',
            block: 'Блок',
            button: 'Посмотреть все блоки',
        },

        transactions: {
            title: 'Последние транзакции',
            button: 'Просмотреть все транзакции',
        }
    },

    selectRecord: {
        show: 'Показать',
        toPage: 'На странице',
    },

    pagination: {
        page: 'Страница',
        of: 'из',
    },

    table: {
        height: 'Высота блока',
        time: 'Время',
        transaction: 'Транзакции',
        sum: 'Сумма',
        fee: 'Комиссия',
        purse: 'Кошелек',
        from: 'От',
        to: 'Кому',
        baseGoal: 'Базовая цель',
        your: 'Ваш кошелек',
        yours: 'Вам на кошелек',
    },

    cardTx: {
        heightBlock: 'Высота блока',
        transaction: 'Транзакция',
        block: 'Блок',
        createdAt: 'Дата создания',
        sum: 'Сумма',
        fee: 'Комиссия',
        recipient: 'Получатель',
        sender: 'Отправитель',
        confirmations: 'Подтверждений в сети',
        senderPublicKey: 'Публичный ключ отправителя',
        signature: 'Сигнатура',
        signatureHash: 'Хэш сигнатуры',
        fullHash: 'Полный хэш',
    },

    cardBlock: {
        heightBlock: 'Высота блока',
        block: 'Блок',
        createdAt: 'Дата создания',
        total: 'Общая сумма',
        count: 'Количество транзакции',
        numberOfTransactions: '%{count} транзакций в блоке',
        purse: 'Кошелек',
        previousBlockHash: 'Предыдущий хэш блока',
        generationSignature: 'Сигнатура',
        generatorPublicKey: 'Публичный ключ',
        blockSignature: 'Сигнатура блока',
    },

    address: {
        purse: 'Кошелек',
        publicKey: 'Публичный ключ',
        balanceNQT: 'Баланс кошелька',
        forged: 'Сфорженно нодой кошелька',
        transaction: 'Транзакции',
        block: 'Блоки',
        notResult: 'Нет данных',

        paramining: {
            dayIncome: 'Доход в день',
            monthIncome: 'Доход в месяц',
            yearIncome: 'Доход в год',
            paramining: 'Парамайнинг',
            paraminingday: 'Парамайнинг в день',
            structure: 'Структура',
            percent: 'Процент',
        }
    },

    copy: {
        heightBlock: 'Высота блока скопирована',
        block: 'Блок скопирован',
        address: 'Адрес скопирован',
        transaction: 'Транзакция скопирована',
        senderPublicKey: 'Ключ скопирован',
    },

    clickPlaceholder: {
        transaction: 'Нажмите, чтобы посмотреть транзакции',
        heightBlock: 'Нажмите, чтобы посмотреть блок высоты',
        address: 'Нажмите, чтобы просмотреть учетную запись',
        nextBlock: 'Посмотреть следующий блок',
        prevBlock: 'Посмотреть предыдущий блок',
    },

    errorNextHeight: 'Ошибка получения блока',

    footer: {
        col_title_products: 'Продукты',
        col_title_news: 'Новости',
    }
};
