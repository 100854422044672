import {LANGUAGE, THEME, TYPE_APP_PENDING_STATE, TYPE_APP_STATE, TYPE_APP_THEME_STATE} from "../../constants";

const appDefaultState = {
    config: {
        theme: THEME.light,
        language: LANGUAGE
    },
    getState: {},
    getConstants: {},
    genesis: {
        accountRS: '',
        publicKey: '',
        balanceNQT: 0,
        forgedBalanceNQT: 0,
        unconfirmedBalanceNQT: 0,
    },
    pending: true,
};

export const appReducer = (state = appDefaultState, action) => {
    switch (action.type) {
        case TYPE_APP_STATE :
            return {
                ...state,
                ...action.payload,
                pending: action.pending,
            };

        case TYPE_APP_THEME_STATE :
            return {
                ...state,
                config: {
                    ...state.config,
                    theme: action.theme
                },
            };
        case TYPE_APP_PENDING_STATE :
            return {
                ...state,
                pending: action.pending,
            };
        default :
            return state;
    }
};
