import React from "react";
import {I18n} from "react-redux-i18n";
import LeftSideBar from "./sideBar/left";
import RightSideBar from "./sideBar/right";
import UIPreloader from "../@common/preloader";

function Main(props) {
    const {
        transactionsPerHour,
        heightPerHour,
        blockGenerationTime,
        avgNumberPerBlock,
        pending,
    } = props;

    return (
        <>
            <div className="mb-4">
                <UIPreloader enable={pending}/>
                <div id="ContentPlaceHolder1_mainboxes" className="card mb-4">
                    <div className="card-body">
                        <div className="row mx-gutters-md-1">
                            <div className="col-md-6 col-lg-6 u-ver-divider u-ver-divider--none-md">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <h2 className="font-size-1 text-uppercase mb-0">
                                            {I18n.t('home.block_cont_transaction')}
                                        </h2>
                                        <span className="js-counter text-counter">{transactionsPerHour}</span>
                                    </div>
                                </div>
                                <hr className="hr-space-text-counter"/>
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <h2 className="font-size-1 text-uppercase mb-0">
                                            {I18n.t('home.block_cont_transaction_hour')}
                                        </h2>
                                        <span className="js-counter text-counter">{heightPerHour}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 u-ver-divider u-ver-divider--none-md">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <h2 className="font-size-1 text-uppercase mb-0">
                                            {I18n.t('home.block_time_created_transaction')}
                                        </h2>
                                        <span className="js-counter text-counter">{blockGenerationTime}</span>
                                    </div>
                                </div>
                                <hr className="hr-space-text-counter"/>
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <h2 className="font-size-1 text-uppercase mb-0">
                                            {I18n.t('home.block_count_average_transaction')}
                                        </h2>
                                        <span className="js-counter text-counter">{avgNumberPerBlock}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <LeftSideBar {...props} />
                    <RightSideBar {...props}/>
                </div>
            </div>
        </>
    )
}

export default React.memo(Main);
