import {getBlocksTransactions} from "../../@api";
import {MAIN_TRANSACTIONS_LAST_INDEX, TYPE_MAIN_PENDING_STATE, TYPE_MAIN_STATE} from "../../constants";

export function fetchMainData() {
    return (dispatch) => {
        getBlocksTransactions({includeTransactions: true, firstIndex: 0, lastIndex: 4})
            .then(response => {
                const result = response.data;
                dispatch(setStateMain(prepareData(result)));
            })
            .finally(() => dispatch(pending(false)))
    }
}

function prepareData(result) {
    let transactions = [];
    let startTime = 0;
    let endTime = 0;
    let totalTransactions = 0;
    let totalBlock = 0;
    let time = 0;

    if (result.blocks.length) {
        startTime = result.blocks[result.blocks.length - 1].timestamp;
        endTime = result.blocks[0].timestamp;
        time = endTime - startTime;
    }


    result.blocks.forEach(block => {
        totalTransactions += block.numberOfTransactions;
        totalBlock += 1;

        block.transactions.forEach((i) => {
            if (transactions.length >= MAIN_TRANSACTIONS_LAST_INDEX) {
                return false;
            }
            transactions.push(i);
        });
    });

    return {
        transactionsPerHour: Math.round(totalTransactions / (time / 60) * 60),
        heightPerHour: Math.round(totalBlock / (time / 60) * 60),
        blockGenerationTime: Math.round(time / MAIN_TRANSACTIONS_LAST_INDEX) + 's',
        avgNumberPerBlock: totalTransactions / totalBlock,
        blocks: result.blocks,
        transactions: transactions,
    }
}

const setStateMain = data => ({
    type: TYPE_MAIN_STATE,
    payload: data,
    pending: false,
});

const pending = (pending = true) => ({
    type: TYPE_MAIN_PENDING_STATE,
    pending: pending,
});
