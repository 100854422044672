import {TYPE_ACCOUNT_PENDING_STATE, TYPE_ACCOUNT_RESET_STATE, TYPE_ACCOUNT_STATE} from "../../constants";

export const addressDefaultState = {
    account: {
        name: '',
        description: '',
        accountRS: '',
        publicKey: '',
        balanceNQT: 0,
        forgedBalanceNQT: 0,
    },
    paramining: {
        profitperday: 0,
        profitpermonth: 0,
        profitperyear: 0,
        paramining: null,
        paraminingday: null,
        balancestructure: 0,
        paraminingpercent: 0,
        forged: 0,
    },
    transactions: [],
    blocks: [],
    moreT: false,
    moreB: false,
};

export const addressReducer = (state = addressDefaultState, action) => {
    switch (action.type) {
        case TYPE_ACCOUNT_STATE :
            return {
                ...state,
                ...action.payload,
                pending: false,
            };
        case TYPE_ACCOUNT_RESET_STATE :
            return {
                ...action.payload,
            };
        case TYPE_ACCOUNT_PENDING_STATE :
            return {
                ...state,
                pending: action.pending,
            };
        default :
            return state;
    }
};
