import React, {useEffect} from "react";
import Height from "./index";
import {connect} from "react-redux";
import {fetchHeight} from "../../store/height/action";
import {animateScroll} from 'react-scroll';

function HeightContainer(props) {

    const {height} = props.match.params;

    useEffect(() => {
        props.getHeight(height).then(() => animateScroll.scrollToTop());
    }, [height]);

    return<Height {...props.height}/>;
}

const mapStateToProps = state => ({
    height: state.height,
});

const mapDispatchTopProps = dispatch => ({
    getHeight: (height) => dispatch(fetchHeight(height)),
});

export default connect(mapStateToProps, mapDispatchTopProps)(HeightContainer);
