import React from "react";
import {Link} from "react-router-dom";
import {I18n} from "react-redux-i18n";
import {asFormatNumber} from "../../../../@helper";

function RightSideBar(props) {

    const {transactions, numberOfBlocks} = props;
    const countItem = transactions.length;

    return (
        <div className="col-lg-6">
            <div className="card h-100">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h2 className="card-header-title">{I18n.t('home.transactions.title')}</h2>
                </div>
                <section>
                    <div className="js-scrollbar card-body transaction-main-block-scrollbar">
                        {transactions.map((transaction, idx) => {
                            return (
                                <div key={idx}>
                                    <div className="row">
                                        <div className='col-sm-5'>
                                            <div className='row media align-items-sm-center mr-4 mb-1 mb-sm-0'>
                                                <div className='d-none d-sm-flex mr-2'><span
                                                    className='btn btn-icon btn-soft-secondary rounded-circle'><span
                                                    className='btn-icon__inner text-dark'>Tx</span></span></div>
                                                <div className='media-body col-sm-6'>
                                                    <span
                                                        className='d-inline-block d-sm-none mr-1'>TX#</span>
                                                    <Link
                                                        className='hash-tag hash-tag--xs hash-tag-xs-down--md text-truncate'
                                                        to={`/tx/${transaction.transaction}`}>{transaction.transaction}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-7'>
                                            <div className='d-sm-flex justify-content-between'>
                                                <div className='row text-nowrap'>
                                                        <span className='d-sm-block col-sm-12'>От&nbsp;
                                                            <Link
                                                                style={{marginLeft: '31px'}}
                                                                className='hash-tag-main text-truncate'
                                                                to={`/address/${transaction.senderRS}`}>{transaction.senderRS}</Link>
                                                        </span>
                                                    <span className='d-sm-block col-sm-12'>Кому
                                                        <Link
                                                            to={`/address/${transaction.recipientRS}`}
                                                            style={{paddingLeft: '18px', maxWidth: '170px'}}
                                                            className='hash-tag-main text-truncate'>{transaction.recipientRS}</Link>
                                                        </span>
                                                </div>
                                                <div>
                                                <span
                                                    className='u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap'
                                                    title='Amount'>{asFormatNumber(transaction.amountNQT)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={countItem === (idx + 1) ? {display: 'none'} : {}} className='hr-space'/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="card-footer">
                        <Link className="btn btn-xs btn-block btn-soft-primary" to={`/txs/${numberOfBlocks - 1}`}>{I18n.t('home.transactions.button')}</Link>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default React.memo(RightSideBar);
