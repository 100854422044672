import React, {useEffect} from "react";
import {connect} from "react-redux";
import Tx from "./index"
import {animateScroll} from 'react-scroll';
import {fetchTx} from "../../store/tx/action";

function TxContainer(props) {

    const {tx} = props.match.params;

    useEffect(() => {
        props.getTx(tx).then(() => animateScroll.scrollToTop());
    }, []);

    return <Tx {...props.tx}/>;
}

const mapStateToProps = state => ({
    tx: state.tx,
});

const mapDispatchTopProps = dispatch => ({
    getTx: (tx) => dispatch(fetchTx(tx)),
});

export default connect(mapStateToProps, mapDispatchTopProps)(React.memo(TxContainer));
