import {TYPE_HEIGHT_PENDING_STATE, TYPE_HEIGHT_STATE} from "../../constants";

const heightDefaultState = {
    block: {},
    pending: true,
};

export const heightReducer = (state = heightDefaultState, action) => {
    switch (action.type) {
        case TYPE_HEIGHT_STATE :
            return {
                ...state,
                block: action.payload,
                pending: false,
            };
        case TYPE_HEIGHT_PENDING_STATE :
            return {
                ...state,
                pending: action.pending,
            };
        default:
            return state;
    }
};
